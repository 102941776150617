var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-dialog",
    {
      attrs: { position: "top" },
      model: {
        value: _vm.noticedialog,
        callback: function ($$v) {
          _vm.noticedialog = $$v
        },
        expression: "noticedialog",
      },
    },
    [
      _c(
        "q-card",
        { staticClass: "mainpoplayerCard" },
        [
          _c(
            "q-bar",
            { staticClass: "bg-orange-custom text-white mainpoplayerHead" },
            [
              _c("q-icon", { attrs: { color: "white", name: "mail" } }),
              _c("q-toolbar-title", [
                _c("span", { staticClass: "text-weight-bold" }, [
                  _vm._v(_vm._s(_vm.setLabel("LBL0000700"))),
                ]),
              ]),
              _c("q-btn", {
                directives: [{ name: "close-popup", rawName: "v-close-popup" }],
                attrs: { flat: "", round: "", dense: "", icon: "close" },
              }),
            ],
            1
          ),
          _c(
            "q-card-section",
            { staticClass: "row items-center no-wrap mainpoplayer" },
            [
              _c(
                "q-list",
                { staticStyle: { width: "100%" }, attrs: { bordered: "" } },
                _vm._l(_vm.popNotices, function (tag) {
                  return _c(
                    "q-expansion-item",
                    {
                      key: tag.sysNoticeId,
                      attrs: {
                        group: "noticepops",
                        "header-class": "bg-gray-custom text-gray",
                        "expand-icon-class": "text-gray",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c(
                                  "q-item-section",
                                  { attrs: { avatar: "" } },
                                  [
                                    _c("q-icon", {
                                      attrs: {
                                        color: "gray",
                                        name: "check",
                                        size: "13px",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("q-item-section", [
                                  _c("b", [_vm._v(_vm._s(tag.noticeTitle))]),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: tag.expanded,
                        callback: function ($$v) {
                          _vm.$set(tag, "expanded", $$v)
                        },
                        expression: "tag.expanded",
                      },
                    },
                    [
                      _c(
                        "q-card",
                        [
                          _c("q-card-section", [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(tag.noticeContents),
                              },
                            }),
                          ]),
                          _c(
                            "q-card-section",
                            [
                              _c("c-upload-list", {
                                attrs: {
                                  attachInfo: {
                                    taskClassCd: "NOTICE",
                                    taskKey: tag.sysNoticeId,
                                  },
                                  editable: false,
                                  label: _vm.$comm.getLangLabel("LBL0000587"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "q-card",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: tag.dayViewDisable,
                              expression: "tag.dayViewDisable",
                            },
                          ],
                        },
                        [
                          _c(
                            "q-card-section",
                            [
                              _c("q-toggle", {
                                staticClass: "anymoreNotice",
                                attrs: {
                                  size: "sm",
                                  "checked-icon": "check",
                                  color: "red",
                                  label: _vm.$comm.getLangMessage("MSG0000137"),
                                  "unchecked-icon": "clear",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.updateTodayChk(tag.sysNoticeId)
                                  },
                                },
                                model: {
                                  value: tag.dayView,
                                  callback: function ($$v) {
                                    _vm.$set(tag, "dayView", $$v)
                                  },
                                  expression: "tag.dayView",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }