<!--
목적 : 설비팝업 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customEquip"
      class="customEquip equipComponent"
      :dense="dense"
      stack-label
      filled
      color="orange-custom"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <template v-if="!isMultiple">
          <q-chip
            v-if="type=='fullpath' && valueTypeText"
            outline square 
            color="blue"
            text-color="white"
            class="q-ma-none customchipdept">
            {{valueTypeText}}
          </q-chip>
          {{valueText}}
        </template>
        <template v-else>
          <q-chip
            v-for="(equip, index) in equips"
            :key="index"
            outline square 
            :removable="!disabled&&editable"
            color="blue"
            text-color="white"
            class="q-ma-none customchipdept"
            @remove="remove(equip)">
            {{equip.equipmentTypeName}} / {{equip.equipmentName}}
          </q-chip>
        </template>
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinCommon from './js/mixin-common'
export default {
  name: 'c-equip',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'fullpath',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    plantCd: {
      type: String,
      default: '',
    },
    processCd: {
      type: String,
      default: '',
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      valueTypeText: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
      equips: [],
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        if (this.value !== null) {
          this.vValue = this.value;
          this.getEquipList();
        } else {
          this.$emit('input', null);
          this.valueText = '';
        }
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.listUrl = selectConfig.mdm.equipment.list.url;
    if (this.value) {
      this.getEquipList();
    }
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customEquip'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customEquip'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    getEquipList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        // plantCd: this.plantCd,
        // processCd: this.processCd,
        equipmentCds: this.isMultiple ? this.vValue : null,
        equipmentCd: !this.isMultiple ? this.vValue : null,
      }
      this.$http.request((_result) => {
        this.equips = _result.data;
        this.setValueText();
      },);
    },
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        if (!this.equips || this.equips.length === 0) {
          this.$emit('input', null);
          this.valueText = '';
          this.valueTypeText = '';
        } else if (this.equips && this.equips.length > 0 && !this.isMultiple) {
          let equip = this.$_.find(this.equips, { equipmentCd: this.vValue });
          if (equip) {
            if (this.type === 'fullpath') {
              this.valueTypeText = equip.equipmentTypeName;
              this.valueText = ' ' + equip.equipmentName;
              // this.valueText = `[${equip.equipmentTypeName}] ${equip.equipmentName}`;
            } else {
              this.valueText = equip.equipmentName
              this.valueTypeText = '';
            }
          } else {
            this.$emit('input', null);
            this.valueText = '';
            this.valueTypeText = '';
          }
        }
      } else {
        this.valueText = '';
      }
    },
    search() {
      this.popupOptions.title = 'LBL0000521'; // 설비 검색
      this.popupOptions.param = {
        type: this.isMultiple ? 'multiple' : 'single',
        processCd: this.processCd,
        plantCd: this.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, equip => {
          if (this.$_.findIndex(this.equips, { equipmentCd: equip.equipmentCd }) === -1) {
            this.equips.push(equip)
          }
        })
        if (!this.isMultiple) {
          if (this.type === 'fullpath') {
            this.valueTypeText = data[0].equipmentTypeName;
            this.valueText = ' ' + data[0].equipmentName;
          } else {
            this.valueTypeText = '';
            this.valueText = data[0].equipmentName;
          }
          this.$emit('input', data[0].equipmentCd);
          this.$emit('dataChange', data[0], this.vValue, 'ADD');
        } else {
          let equipmentCds = this.$_.map(this.equips, 'equipmentCd').join(',');
          this.$emit('input', equipmentCds);
          this.$emit('dataChange', this.equips, equipmentCds, 'ADD');
        }
      }
    },
    reset() {
      this.valueTypeText = '';
      this.valueText = '';
      this.$emit('input', null);
      this.$emit('dataChange', null);
    },
    remove(equip) {
      // 문자열로 들어온 경우 ** 배열로 들어온 경우는 고려하지 않음
      this.equips = this.$_.reject(this.equips, { equipmentCd: equip.equipmentCd });

      let equipmentCds = this.$_.map(this.equips, 'equipmentCd').join(',');
      this.$emit('input', equipmentCds);
      this.$emit('dataChange', this.equips, equipmentCds, 'DELETE');
    },
  },
};
</script>
<style lang="sass">
.customEquip
  padding-bottom: 20px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 6px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customEquip:hover
  .dateCloseIcon
    display: block
.customEquip.q-field--dense .q-field__control, .customEquip.q-field--dense .q-field__marginal
  height: min-content !important
  min-height: 33px !important
.customEquip.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
.equipComponent > .q-field__inner > .q-field__control > .q-field__control-container > .q-field__native
  align-items: initial
</style>