var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "td-input-column" }, [
    !_vm.isShow
      ? _c("div", { staticClass: "pad-txt-label" }, [
          _vm.col.disableData && _vm.disabled && _vm.vValue == 0
            ? _c(
                "div",
                {
                  ref: "input-text",
                  class: ["text-center"],
                  attrs: { tabindex: "0" },
                  on: {
                    focus: function ($event) {
                      return _vm.numberfocus()
                    },
                    click: function ($event) {
                      return _vm.numberfocus()
                    },
                  },
                },
                [_vm._v(" - ")]
              )
            : _c(
                "div",
                {
                  ref: "input-text",
                  class: [
                    "text-right",
                    _vm.disabled ? "" : "table-td-numberlabel",
                    _vm.colorClass ? _vm.colorClass : "",
                  ],
                  attrs: { tabindex: "0" },
                  on: {
                    focus: function ($event) {
                      return _vm.numberfocus()
                    },
                    click: function ($event) {
                      return _vm.numberfocus()
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm._f("toThousandFilter")(_vm.vValue)) + " "
                  ),
                ]
              ),
        ])
      : _vm._e(),
    _vm.editable && _vm.isShow
      ? _c(
          "div",
          [
            _c("q-input", {
              ref: "input-column",
              staticClass: "table-td-text",
              attrs: {
                type: "number",
                outlined: "",
                autofocus: "",
                disable: _vm.disabled,
              },
              on: {
                keyup: _vm.keyup,
                blur: function ($event) {
                  return _vm.numberfocusout()
                },
              },
              model: {
                value: _vm.vValue,
                callback: function ($$v) {
                  _vm.vValue = $$v
                },
                expression: "vValue",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }