var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container app-theme-white page-layout" },
    [
      _vm.modeEnv
        ? _c("div", { staticClass: "envTxtPage" }, [
            _vm._v(_vm._s(_vm.modeEnv)),
          ])
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }