var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fix-height" },
    [
      _c("q-field", {
        ref: "customLocation",
        staticClass: "customLocation",
        attrs: {
          dense: _vm.dense,
          "stack-label": "",
          filled: "",
          color: "orange-custom",
          label: _vm.convertLabel,
          disable: _vm.disabled || !_vm.editable,
          rules: !_vm.required ? null : [(val) => !!val || ""],
        },
        scopedSlots: _vm._u(
          [
            _vm.label
              ? {
                  key: "label",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "row items-center all-pointer-events" },
                        [
                          _c(
                            "b",
                            [
                              !_vm.required
                                ? _c("i", {
                                    staticClass:
                                      "pe-7s-note labelfrontIcon searchAreaLabelIcon",
                                  })
                                : _vm._e(),
                              _c("font", { staticClass: "formLabelTitle" }, [
                                _vm._v(_vm._s(_vm.convertLabel)),
                              ]),
                              _vm.required
                                ? _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons labelfrontIcon text-requiredColor",
                                    },
                                    [_vm._v("check")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "control",
              fn: function () {
                return [
                  !_vm.isMultiple
                    ? [
                        !_vm.isEtc
                          ? [_vm._v(" " + _vm._s(_vm.valueText) + " ")]
                          : _vm.isEtcExpression && _vm.isEtc
                          ? [
                              _c(
                                "q-chip",
                                {
                                  staticClass: "q-ma-none customchipdept",
                                  attrs: {
                                    outline: "",
                                    square: "",
                                    clickable: "",
                                    color: "deep-purple",
                                    "text-color": "white",
                                    icon: "edit",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.clickEtc.apply(null, arguments)
                                    },
                                  },
                                },
                                [_vm._v(" 기타 ")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data[_vm.etcInput],
                                    expression: "data[etcInput]",
                                  },
                                ],
                                ref: "location-single-etc-input",
                                staticClass: "location-etc-input",
                                domProps: { value: _vm.data[_vm.etcInput] },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.data,
                                      _vm.etcInput,
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          : _vm._e(),
                      ]
                    : [
                        _vm._l(
                          _vm.rejectEtcLocations,
                          function (location, index) {
                            return _c(
                              "q-chip",
                              {
                                key: index,
                                staticClass: "q-ma-none customchipdept",
                                attrs: {
                                  outline: "",
                                  square: "",
                                  removable: !_vm.disabled && _vm.editable,
                                  color: _vm.isMapShowing
                                    ? location.sopMapLocationId
                                      ? "blue"
                                      : "red"
                                    : "orange",
                                  "text-color": "white",
                                },
                                on: {
                                  remove: function ($event) {
                                    return _vm.remove(location)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(location.locationName) + " "
                                ),
                                _vm.isMapShowing
                                  ? [
                                      _c(
                                        "q-tooltip",
                                        {
                                          attrs: {
                                            "content-class":
                                              "bg-amber text-black shadow-4",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                location.sopMapLocationId
                                                  ? "배치도면 연결 O"
                                                  : "배치도면 연결 X"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }
                        ),
                        _vm.etcLocation && _vm.isEtcExpression
                          ? [
                              _c(
                                "q-chip",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isEtcWrite,
                                      expression: "!isEtcWrite",
                                    },
                                  ],
                                  staticClass: "q-ma-none customchipdept",
                                  attrs: {
                                    outline: "",
                                    square: "",
                                    clickable: "",
                                    removable: !_vm.disabled && _vm.editable,
                                    color: "deep-purple",
                                    "text-color": "white",
                                    icon: "edit",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.clickEtc.apply(null, arguments)
                                    },
                                    remove: function ($event) {
                                      return _vm.remove(_vm.etcLocation)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " (기타) " +
                                      _vm._s(_vm.data[_vm.etcInput]) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isEtcWrite,
                                    expression: "isEtcWrite",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data[_vm.etcInput],
                                    expression: "data[etcInput]",
                                  },
                                ],
                                ref: "location-etc-input",
                                staticClass: "location-etc-input",
                                domProps: { value: _vm.data[_vm.etcInput] },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                  blur: _vm.etcBlur,
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.etcBlur.apply(null, arguments)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.data,
                                      _vm.etcInput,
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          : _vm._e(),
                      ],
                ]
              },
              proxy: true,
            },
            {
              key: "append",
              fn: function () {
                return [
                  !_vm.disabled && _vm.editable
                    ? _c("q-icon", {
                        staticClass: "cursor-pointer",
                        attrs: { name: "search" },
                        on: { click: _vm.search },
                      })
                    : _vm._e(),
                  !_vm.disabled && _vm.editable
                    ? _c("q-icon", {
                        staticClass: "cursor-pointer",
                        attrs: { name: "close" },
                        on: { click: _vm.reset },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.valueText,
          callback: function ($$v) {
            _vm.valueText = $$v
          },
          expression: "valueText",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }