<template>
  <div class="header-dots">
    <div class="header-btn-lg topfavoriteicon">
      <button type="button" class="material-icons star"
        v-bind:class="{ 'is-active': showDrawerSection }" @click="showDrawerSection = !showDrawerSection">star
      </button>
    </div>
    <div class="app-drawer-wrapper" v-bind:class="{ 'drawer-open': showDrawerSection }">
      <div class="drawer-nav-btn right-sidebar-menu-top-btn">
        <button type="button" class="material-icons clear"
          v-bind:class="{ 'is-active': showDrawerSection }" @click="showDrawerSection = !showDrawerSection">clear
        </button>
      </div>
      <div class="drawer-content-wrapper right-sidebar-menu-top">
        <!-- 즐겨찾기 -->
        <h3 class="drawer-heading">{{setLabel('LBL0000861')}}</h3>
        <div class="col-12">
          <draggable
            tag="ul"
            :list="menufavorite"
            class="list-group2"
            handle=".linehandle2"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            @change="moveApprLine"
            :move="checkMove"
          >
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <li
                class="list-group-item2 linehandle2"
                v-for="(element) in menufavorite"
                :key="element.sysMenuId"
              >
                <i class="material-icons linehandley2">reorder</i>
                <div class="linetext2" @click="movePageTab(element.href)">{{ element.title }} <q-tooltip anchor="center left" self="center right">{{ element.title }}</q-tooltip></div>
                <i class="material-icons lineclose2" @click="removeAt(element.sysMenuId)">clear</i>
              </li>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import draggable from 'vuedraggable';
import mixinConvert from '@/js/mixin-template-convert'
export default {
  components: {
    draggable,
  },
  mixins: [mixinConvert],
  data() {
    return {
      showDrawerSection: false,
      dragging: false,
      drag: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    menufavorite() {
      return this.$store.state.app.favorites;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    init() { 
      this.$store.dispatch('GetFavorites', {userId: this.$store.getters.user.userId});
    },
    movePageTab(_page) {
      this.$comm.movePage(this.$router, _page)
    },
    moveApprLine() {
      let _cnt = 1;
      this.$_.forEach(this.menufavorite, item => {
        item.sortOrder = _cnt;
        _cnt++;
      })
      this.$http.url = transactionConfig.sys.favorite.save.url;
      this.$http.type = 'POST';
      this.$http.param = this.menufavorite
      this.$http.request(() => {
        this.$store.dispatch('GetFavorites', {userId: this.$store.getters.user.userId});
      });
    },
    checkMove: function(e) {
      if (e.draggedContext.futureIndex === 0) {
        return false;
      }
    },
    removeAt(_sysMenuId) {
      this.$http.url = transactionConfig.sys.favorite.insert.url;
      this.$http.type = 'PUT';
      this.$http.param = {
        userId: this.$store.getters.user.userId,
        sysMenuId: _sysMenuId,
      };
      this.$http.request(() => {
        this.$store.dispatch('GetFavorites', {userId: this.$store.getters.user.userId});
      });
    },
  }
};
</script>
<style lang="sass">
.topfavoriteicon button
  color: #fff !important
  background: inherit
  border-width: 0px
  cursor: pointer
.right-sidebar-menu-top-btn button
  color: #fff !important
  background: inherit
  border-width: 0px
  cursor: pointer
</style>

<style scoped>
.linehandley2 {
  float: left;
  margin-left: -10px;
  padding-top: 4px;
  padding-right: 10px;
  cursor: move;
}
.lineclose2 {
  float: right;
  padding-top: 4px;
  font-size: 12px;
  margin-right: -10px;
  cursor: pointer;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.3;
  background: #c8ebfb;
}
.list-group2 {
  min-height: 20px;
  list-style: none;
}
.linespan1 {
  min-width: 50px !important;
  text-align: left;
  font-weight: 600;
}
.linespan2 {
  min-width: 120px !important;
  text-align: left;
}
.linetext2 {
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
}
.list-group-item2 {
  padding: 0.4rem  1.25rem !important;
  margin-bottom: -1px;
  background-color: #fff;
  border-width: 0px;
  font-size: 0.8rem;
  color: #000;
}
</style>

