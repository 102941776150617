/**
 * backend와 통신하기 위한 axios 설정
 */
/* eslint-disable */
import axios from 'axios';
import backendConfig from '@/js/backendConfig.js';
import queryString from 'qs';
// import { getToken } from '@/utils/auth';
import request from '@/utils/request';
import {
  getAccessToken,
  getNewToken,
  getAccessExpiredCode,
  getRefreshExpiredCode,
  getWrongPasswordCode,
  getConcurrentExpiredCode,
} from '../utils/auth';
import common from './common';
import $store from '@/store';

// axios 공통함수
var http = {
  url: null,
  param: null,
  type: 'GET',
  async: true,
  isSetHeader: true,
  isAuthCheck: false,
  processData: true,
  dataType: 'json',
  ajaxPid: null, // ajax 요청의 key값 millisecond 데이터
  accept: null,
  request: null,
  requestGet: null,
  requestPost: null,
  requestPut: null,
  requestFile: null,
  defaultErrorHandler: null,
  getErrorMessage: null,
  isLogin: null,
  isFileRequestPost: false,
  isLoading: true,
};

var orgHttp = {
  url: null,
  param: null,
  type: 'GET',
  async: true,
  isSetHeader: true,
  isAuthCheck: false,
  processData: true,
  dataType: 'json',
  ajaxPid: null,
  isLoading: true,
};

var stack = [];

// 기본 axios
http.request = function(_callbackSuccess, _callbackFail) {
  // 현재 프로토콜, 호스트를 조합하여 url 설정 (내/외부 접속)
  // BACKEND 직접 호출 시, 주석처리
  if (!http.url) return null;
  var url = backendConfig.getUrl(http.url);
  var key = '';
  var method = http.type.toLowerCase();
  // console.log("url : " + url);
  // console.log("type : " + method);
  // console.log("param : ");
  // console.log(JSON.stringify(http.url));
  // console.log(JSON.stringify(http.param));

  var param = {};
  var query = '';
  var paramKey = '';
  if (method === 'get') {
    let tempParam = http.param;
    let defaultVendorCd = window.sessionStorage.getItem('defaultVendorCd')
    if (!tempParam) {
      tempParam = {
        lang: common.getLang(),
        defaultVendorCd: defaultVendorCd ? defaultVendorCd : '' // 글로벌 VendorCd
      }
    } else {
      tempParam.lang = common.getLang()
      tempParam.defaultVendorCd = defaultVendorCd ? defaultVendorCd : '' // 글로벌 VendorCd
    }
    // 사업장 조회시 권한이 있는 사업장을 plantCdList에 배열로 담아 API로 전달
    if (tempParam.plantCd) { 
      tempParam.plantCdList = [];
      let plantlist = tempParam.plantCd.split(',');
      for (let i = 0; i < plantlist.length; i++) { 
        if (plantlist[i] !== '') { 
          tempParam.plantCdList.push(plantlist[i]);
        }
      }
    }
    query = queryString.stringify(tempParam);
    param = {
      params: tempParam,
      paramsSerializer: () => {
        return query;
      },
    };
  } else {
    if (http.isFileRequestPost) {
      param = new FormData();

      for (paramKey in http.param) {
        if (http.param.hasOwnProperty(paramKey)) {
          if (paramKey !== 'files') {
            param.append(paramKey, http.param[paramKey]);
          }
        }
        if (http.param.hasOwnProperty('files') && paramKey === 'files') {
          if (http.param.files && http.param.files.length > 0) {
            // eslint-disable-next-line max-depth
            for (let i = 0; i < http.param.files.length; i++) {
              param.append('files', http.param.files[i], http.param.files[i].name);
              param.append('tempId', http.param.files[i].tempId);
            }
          } else {
            param.append('tempId', '');
          }
        }
      }
    } else {
      param = http.param;
    }
  }

  var errorMessage = '';
  var errorDetail = '';
  /**
   * 서버통시시 공용 로딩바 사용
   */
  if (http.isLoading) {
    window.getApp.$emit('LOADING_SHOW');
  }

  // 19.08.30 axios 인증토큰 헤더 추가
  axios.defaults.headers.common['X-Authorization'] = getAccessToken();

  let configHeader = {
    headers: {
      // Pragma: 'no-cache'
      'Cache-Control': 'no-cache',
    },
  };
  stack.push('queue')
  // setTimeout(() => {
    axios[method](url, param, configHeader)
      .then(_result => {
        for (key in orgHttp) {
          if (http.hasOwnProperty(key)) http[key] = orgHttp[key];
        }
        if (typeof _callbackSuccess === 'function') {
          _callbackSuccess(_result);
        }

        stack.pop();
        if (stack.length === 0 &&  http.isLoading) {
          window.getApp.$emit('LOADING_HIDE');
        }

        /**
         * 2021.09.10 kdh
         * 현재 로그 쌓는 부분이 없음으로 해당 부분 주석 처리
         */
        // let deleteUrl = backendConfig.getHttpAddress();
        // let impoQuery = queryString.stringify({
        //   servletPath: url,
        //   crudCd: method,
        //   deleteUrl: deleteUrl,
        //   // data: param,
        //   data: '',
        //   userId: getToken(),
        // });
        // data에 parameter를 받아서 로그에 자세한 내용을 담을 수 있으나
        // 길이가 길면 담지 못함ㅠ
        // let impoParams = {
        //   params: {
        //     servletPath: url,
        //     crudCd: method,
        //     deleteUrl: deleteUrl,
        //     // data: param,
        //     data: '',
        //     userId: getToken(),
        //   },
        //   paramsSerializer: () => {
        //     return impoQuery;
        //   },
        // };

        // // crud log 제외 ServletPath
        // let ignoreServletPaths = ['api/manage/user/favorite',
        //   'api/manage/codemaster/getselect',
        //   'api/manage/codemaster/getselectattr',
        //   'api/manage/treedepts',
        //   'api/main/portlet'];
        // var isIgnore = false;
        // ignoreServletPaths.forEach(function(item) {
        //   if (impoParams.params.servletPath.indexOf(item) > -1) {
        //     isIgnore = true;
        //     return;
        //   }
        // });
        // if (!isIgnore) {
        //   axios
        //     .get(deleteUrl + '/api/manage/log/crudlog', impoParams)
        //     .then(() => {});
        // }
      }).catch(_error => {
        stack = [];
        try {
          errorMessage = _error.response.data;
          errorDetail = _error.response
        } catch (e) {
          errorMessage = _error;
          errorDetail = _error;
        }

        const status = _error.response ? _error.response.status : null;
        const returnCode = _error.response
          ? _error.response.data.returnCode
          : null;

        if (status === 401) {
          if (returnCode === getAccessExpiredCode()) {
            // Access Token 만료 : Refresh Token으로 재인증 요청
            // 1. Refresh Token 확인 요청
            return getNewToken()
              .then(() => {
                // 2. 새로 발급된 Access Token 으로 재요청
                let token = getAccessToken();
                axios.defaults.headers.common['X-Authorization'] = token;

                // 이전요청을 다시 수행하도록 url및 parameter등을 다시 호출.
                return request(_error.config);
              })
              .catch(() => {
                // console.log('::: 만료토큰으로 처리되었음. 재로그인 필요.');
                // common.goLogin();
                $store.dispatch('Clear').then(() => {
                  window.sessionStorage.removeItem('defaultPlantCd');
                  location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
                }) 
              });
          } else if (returnCode === getRefreshExpiredCode()) {
            // Refresh Token 만료
            // 1. 비밀번호 확인 팝업 띄우기
            for (key in orgHttp) {
              if (http.hasOwnProperty(key)) http[key] = orgHttp[key];
            }
            // common.goLogin();
            $store.dispatch('Clear').then(() => {
              window.sessionStorage.removeItem('defaultPlantCd');
              location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
            }) 
          } else if (returnCode === getWrongPasswordCode()) {
            // 비밀번호가 맞지 않을 때만 진입
            return Promise.reject(_error);
          } else if (returnCode === getConcurrentExpiredCode()) {
            // 새로운 IP로 로그인 했을 때만 진입
            return;
          } else {
            // common.goLogin();
            $store.dispatch('Clear').then(() => {
              window.sessionStorage.removeItem('defaultPlantCd');
              location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
            }) 
          }
        } else {
          // 개발자 에러 메시지
          if (process.env.NODE_ENV !== 'production') {
            window.getApp.$emit('NOTIFY_EXCEPTION', {
              title: '[개발자 안내]', // [개발자 안내]
              message: errorMessage,
              errorDetail: errorDetail,
              type: 'error',
              case: 'development',
              duration: 0, // 자동으로 닫히지 않음
            });
          } else {
            window.getApp.$emit('APP_REQUEST_ERROR', '서비스 처리 중 오류가 발생하였습니다.\n\r관리자에게 문의바랍니다.');
          }

          for (key in orgHttp) {
            if (http.hasOwnProperty(key)) http[key] = orgHttp[key];
          }
          if (typeof _callbackFail === 'function') _callbackFail(errorMessage);
        }
        if (http.isLoading) {
          window.getApp.$emit('LOADING_HIDE');
        }
      });
    // }
  // }, 200);
};

http.requestGet = function(_callbackSuccess, _callbackFail) {
  http.type = 'GET';
  return http.request(_callbackSuccess, _callbackFail);
};

http.requestPost = function(_callbackSuccess, _callbackFail) {
  http.type = 'POST';
  return http.request(_callbackSuccess, _callbackFail);
};

http.requestPut = function(_callbackSuccess, _callbackFail) {
  http.type = 'PUT';
  return http.request(_callbackSuccess, _callbackFail);
};

http.requestDelete = function(_callbackSuccess, _callbackFail) {
  http.type = 'DELETE';
  return http.request(_callbackSuccess, _callbackFail);
};

http.getErrorMessage = function(_error) {
  if (_error.status === 400) {
    return '[400오류]필수 입력값이 입력되지 않았습니다.';
  }
};

export default http;
