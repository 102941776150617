export default {
  props: {
  },
  computed: {
  },
  data() {
    return {
    };
  },
  methods: {
    setLabel(label) {
      return this.$comm.getLangLabel(label);
    },
    setMessage(message) {
      return this.$comm.getLangMessage(message);
    },
  },
}
  