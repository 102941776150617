<!--
목적 : 확장검색 기능을 지원하는 컴포넌트
Detail :
 *
examples:
 *
-->
<template>
  <div>
    <q-dialog
      ref="dialog"
      :persistent="false"
      @show="onShow" 
      @hide="onHide"
      :no-esc-dismiss="false"
      no-backdrop-dismiss
      :content-class="{ customDialog: true, 'full-dialog': param.isFull, 'mini-dialog': !param.isFull }"
      :content-style="{ 'opacity' : bodyOpacity }"
      position="top"
      v-model="param.visible">
      <q-layout
        ref="dialog-layout"
        view="Lhh lpR fff"
        class="bg-white no-scroll dialog-layout"
        :style="{ 'max-width': param.isFull ? 'none' : param.width, 'min-height': 'auto', 'max-height': param.isFull ? '98%' : 'calc(100vh - 60px)' }">
        <q-header ref="dialog-header" class="text-white dialog-header" :class="param.isFull ? (param.isApproval ? 'bg-mini-dialog cursor-move' : 'bg-full-dialog') : 'bg-mini-dialog cursor-move'">
          <q-toolbar ref="toolbar-header">
            <q-toolbar-title>{{setLabel(param.title)}}
              <q-chip v-if="param.suffixChip" dense color="white" text-color="main-custom" icon="bookmark">
                {{param.suffixChip}}
              </q-chip>
            </q-toolbar-title>
            <q-btn flat round dense icon="info" v-if="regUserInfo.regUserName">
              <q-tooltip anchor="bottom middle" self="top end">
                <div class="tooltip-layer">
                  <div class="tooltip-title" v-if="regUserInfo.regUserName">{{setLabel('LBL0000714') + ' ' + regUserInfo.regUserName + (regUserInfo.regUserDeptName ? '/' + regUserInfo.regUserDeptName : '')}}</div><div class="tooltip-content" v-if="regUserInfo.regUserName">{{ regUserInfo.regDtStrTime}}</div><br/>
                  <div class="tooltip-title" v-if="regUserInfo.chgUserName">{{setLabel('LBL0000715') + ' ' + regUserInfo.chgUserName + (regUserInfo.chgUserDeptName ? '/' + regUserInfo.chgUserDeptName : '')}} </div><div class="tooltip-content" v-if="regUserInfo.chgUserName">{{ regUserInfo.chgDtStrTime}}</div>
                </div>
              </q-tooltip>
            </q-btn>
            <q-btn flat @click="setFullScreen" round dense :icon="screenIcon" size="12px" v-if="!param.isFull">
              <q-tooltip class="bg-white text-primary">{{ screenIconText }}</q-tooltip>
            </q-btn>
            <q-btn flat @click="closePopup" round dense icon="close">
              <!-- 창닫기 -->
              <q-tooltip class="bg-white text-primary">{{setLabel('LBL0000520')}}</q-tooltip>
            </q-btn>
          </q-toolbar>
        </q-header>
        <q-page-container class="custom-dialog-page-container"
          :style="{ 'overflow-y': 'auto', 'min-height': 'auto', 'max-height': (param.isFull || param.width == '95%' || param.width == '99%' ? 'calc(100vh)' : 'calc(100vh - 100px)') }">
          <q-page padding style="min-height:200px;">
            <div :style="{ 'height': param.isFull  ? 'calc(100vh - 70px)' : param.width == '95%' || param.width == '99%' ? 'calc(100vh - 70px)' : 'auto', }">
              <component
                :is="param.target"
                :style="`background:` + bodyColor"
                :popupParam="param.param"
                :contentHeight="contentHeight"
                :returnData="returnData"
                @setRegInfo="setRegInfo"
                @changeSuffixChip="changeSuffixChip"
                @closePopup="param.closeCallback"
              />
            </div>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>
  </div>
</template>

<script>
var isChrome = /*@cc_on!@*/false || !document.documentMode
import mixinCommon from './js/mixin-common'
export default {
  name: 'c-dialog',
  mixins: [mixinCommon],
  props: {
    param: {
      type: Object,
      default: () => ({
        isFull: true,
        isApproval: false,
        target: null,
        title: '',
        visible: false,
        suffixChip: '',
        width: '',
        height: null,
        top: '',
        param: {},
        closeCallback: null,
      }),
    },
  },
  data() {
    return {
      isFull: true,
      screenIcon: 'fullscreen', // fullscreen_exit
      screenIconText: '', // 
      bodyColor: '#FFFFFF',
      bodyOpacity: '0.99 !important',
      bodyTimer: '',
      subMenuOpen: false,
      maximizedToggle: true,
      contentHeight: 'auto',
      oriWidth: '',
      returnData: { // 최대 3개의 data를 return
        col1: null,
        col2: null,
        col3: null,
      },
      target: null,
      nodeDragg: null,
      regUserInfo: {
        regUserName: '',
        regUserDeptName: '',
        regDtStrTime: '',
        chgUserName: '',
        chgUserDeptName: '',
        chgDtStrTime: '',
      }
    };
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.param.visible && this.bodyTimer) {
      clearTimeout(this.bodyTimer)
    }
    if (!isChrome) {
      this.bodyOpacity = '0.99 !important';
    }
    window.removeEventListener('resize', this.setSize);
  },
  destroyed() {},
  updated() {
    if (this.param.visible) {
      if (!isChrome) {
        this.bodyTimer = setTimeout(this.setBodyTimer,2000)
      } else {
        this.bodyOpacity = '1 !important';
      }
    } else {
      if (!isChrome) {
        this.bodyOpacity = '0.99 !important';
      }
    }
  },
  watch: {
    'param.visible'() {
      this.setSize();
    }
  },
  /* methods */
  methods: {
    init() {
      this.screenIconText = this.$comm.getLangLabel('LBL0000713')
      
    },
    setFullScreen() {
      this.isFull = !this.isFull
      if (this.isFull) {
        this.screenIcon = 'fullscreen_exit';
        this.screenIconText = this.$comm.getLangLabel('LBL0000712');
        this.param.width = '99%'
      } else {
        this.screenIcon = 'fullscreen';
        this.screenIconText = this.$comm.getLangLabel('LBL0000713');
        this.param.width = this.oriWidth;
      }
    },
    setRegInfo(_data) {
      // 상세팝업 최초작성/최근수정 정보 노출
      this.regUserInfo.regUserName =  _data.regUserName;
      this.regUserInfo.regUserDeptName =  _data.regUserDeptName;
      this.regUserInfo.regDtStrTime =  _data.regDtStrTime;
      this.regUserInfo.chgUserName =  _data.chgUserName;
      this.regUserInfo.chgUserDeptName =  _data.chgUserDeptName;
      this.regUserInfo.chgDtStrTime =  _data.chgDtStrTime;
    },
    setBodyTimer() {
      this.bodyOpacity = '1 !important';
      clearTimeout(this.bodyTimer)
    },
    setSize() {
      this.isFull = this.param.isFull;
      this.oriWidth = this.param.width;
      if (this.param.visible) {
        setTimeout(() => {
          let height = this.$refs['dialog-layout'].$el.offsetHeight - this.$refs['dialog-header'].$el.offsetHeight;
          this.contentHeight = !isNaN(height) ? height : 'auto';
        }, 300);
      } else {
        this.contentHeight = 'auto'
      }
    },
    closePopup() {
      this.regUserInfo.regUserName = '';
      this.regUserInfo.regUserDeptName = '';
      this.regUserInfo.regDtStrTime = '';
      this.regUserInfo.chgUserName = '';
      this.regUserInfo.chgUserDeptName = '';
      this.regUserInfo.chgDtStrTime = '';

      this.param.closeCallback(this.returnData)
    },
    onShow() {
      if(!this.param.isFull) {
        let dialogElements = document.getElementsByClassName("mini-dialog");
        this.target = dialogElements[dialogElements.length - 1].querySelector(".dialog-layout");
        this.nodeDragg = this.target.querySelector(".q-header");
        this.nodeDragg.addEventListener("mousedown", this.onGrab);

        this.$emit("onShow");
      }
    },
    onHide() {
      if(!this.param.isFull) {
        document.removeEventListener("mousemove", this.onDrag);
        document.removeEventListener("mouseup", this.onLetGo);
        this.nodeDragg.removeEventListener("mousedown", this.onGrab);
        this.$emit("onHide");
      }
    },
    onDrag(e) {
      if(!this.param.isFull) {
        let originalStyles = window.getComputedStyle(this.target);
        this.target.style.left =
          parseInt(originalStyles.left) + e.movementX + "px";
        this.target.style.top = parseInt(originalStyles.top) + e.movementY + "px";

        if (parseInt(originalStyles.top) + e.movementY < 0) {
          this.target.style.top = "0px"
        }
        if (parseInt(originalStyles.top) + e.movementY + 60 > window.innerHeight) {
          this.target.style.top = (window.innerHeight - 60) + "px"
        }
      }
    },
    onLetGo() {
      if(!this.param.isFull) {
        document.removeEventListener("mousemove", this.onDrag);
        document.removeEventListener("mouseup", this.onLetGo);
      }
    },
    onGrab() {
      if(!this.param.isFull) {
        document.addEventListener("mousemove", this.onDrag);
        document.addEventListener("mouseup", this.onLetGo);
      }
    },
    changeSuffixChip(_suffixChip) {
      this.$set(this.param, 'suffixChip', _suffixChip)
    }
  },
};
</script>
<style lang="sass">
.q-dialog__inner--minimized
  padding: 0px !important
.q-toolbar
  min-height: 42px
  .q-toolbar__title
    font-size: 1.2em
    font-weight: 700
    text-align: center

.full-dialog
  .q-dialog__inner
    height: 100%
</style>