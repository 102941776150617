/* eslint-disable */
import Cookies from 'js-cookie';
import axios from 'axios';

// token은 user_id를 가져오고 있음. 전체바꾸기를 해야함.
const TokenKey = 'jwtToken';
const RefreshTokenKey = 'refreshToken';
const accessTokenKey = 'accessToken';

const accessExpiredCode = 'ACCESS_EXPIRED';
const refreshExpiredCode = 'REFRESH_EXPIRED';
const wrongPasswordCode = 'WRONG_PASSWORD';
const concurrentExpiredCode = 'CONCURRENT_EXPIRED';

const loginUserCode = 'LOGIN_USER';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getAccessToken() {
  return Cookies.get(accessTokenKey);
}

export function setAccessToken(accessToken) {
  return Cookies.set(accessTokenKey, 'Bearer ' + accessToken);
}

export function removeAccessToken() {
  return Cookies.remove(accessTokenKey);
}

export function getRefreshToken() {
  return Cookies.get(RefreshTokenKey);
}

export function setRefreshToken(refreshToken) {
  return Cookies.set(RefreshTokenKey, 'Bearer ' + refreshToken);
}

export function removeRefreshToken() {
  return Cookies.remove(RefreshTokenKey);
}

export function getNewToken() {
  let refToken = getRefreshToken();

  const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 5000, // request timeout
    // responseEncoding: 'utf-8',
    // crossDomain: true,
  });
  const validRefreshCall = service
    .get('/api/auth/refresh', {
      headers: {
        // 요청 헤더
        'X-Authorization': refToken,
      },
    })
    .then(response => {
      let data = response.data;
      setAccessToken(data.accessToken);
      setRefreshToken(data.refreshToken);
      return Promise.resolve(true);
      // return response;
    })
    .catch(error => {
      return Promise.reject(error);
    });

  return validRefreshCall;
}

export function getAccessExpiredCode() {
  return accessExpiredCode;
}

export function getRefreshExpiredCode() {
  return refreshExpiredCode;
}

export function getWrongPasswordCode() {
  return wrongPasswordCode;
}

export function getConcurrentExpiredCode() {
  return concurrentExpiredCode;
}

export function haveRoute(routers, path) {
  let returnVal = false;
  if (routers && routers.length > 0) {
    for (let i = 0; i < routers.length; i++) {
      if (routers[i].path === path) {
        return true;
      } else {
        returnVal = false;
      }
    }
  }
  return returnVal;
}

export function getLoginUser() {
  return Cookies.get(loginUserCode);
}

export function setLoginUser(loginUser) {
  return Cookies.set(loginUserCode, loginUser);
}

export function removeLoginUser(loginUser) {
  return Cookies.remove(loginUserCode);
}