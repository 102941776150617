var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fix-height", class: [_vm.counter ? "c-text-counter" : ""] },
    [
      _vm.type !== "number"
        ? _c("c-text-string", {
            attrs: {
              name: _vm.name,
              placeholder: _vm.placeholder,
              editable: _vm.editable,
              maxlength: _vm.maxlength,
              beforeIcon: _vm.beforeIcon,
              prependIcon: _vm.prependIcon,
              appendIcon: _vm.appendIcon,
              afterIcon: _vm.afterIcon,
              required: _vm.required,
              clearable: _vm.clearable,
              disabled: _vm.disabled,
              readonly: _vm.readonly,
              type: _vm.type,
              counter: _vm.counter,
              close: _vm.close,
              search: _vm.search,
              detail: _vm.detail,
              prefix: _vm.prefix,
              suffix: _vm.suffix,
              label: _vm.label,
              mask: _vm.mask,
              fillMask: _vm.fillMask,
              beforeText: _vm.beforeText,
              dense: _vm.dense,
              phoneNumberCheck: _vm.phoneNumberCheck,
              bizNumberCheck: _vm.bizNumberCheck,
            },
            on: {
              dataChange: _vm.dataChange,
              searchPop: _vm.searchPop,
              detailPop: _vm.detailPop,
              callback: _vm.callback,
            },
            model: {
              value: _vm.vValue,
              callback: function ($$v) {
                _vm.vValue = $$v
              },
              expression: "vValue",
            },
          })
        : _c("c-text-number", {
            attrs: {
              name: _vm.name,
              placeholder: _vm.placeholder,
              editable: _vm.editable,
              maxlength: _vm.maxlength,
              beforeIcon: _vm.beforeIcon,
              prependIcon: _vm.prependIcon,
              appendIcon: _vm.appendIcon,
              afterIcon: _vm.afterIcon,
              required: _vm.required,
              clearable: _vm.clearable,
              disabled: _vm.disabled,
              readonly: _vm.readonly,
              type: _vm.type,
              counter: _vm.counter,
              close: _vm.close,
              search: _vm.search,
              detail: _vm.detail,
              prefix: _vm.prefix,
              suffix: _vm.suffix,
              label: _vm.label,
              mask: _vm.mask,
              fillMask: _vm.fillMask,
              beforeText: _vm.beforeText,
              dense: _vm.dense,
              numberOptions: _vm.numberOptions,
              customClass: _vm.customClass,
            },
            on: {
              dataChange: _vm.dataChange,
              searchPop: _vm.searchPop,
              detailPop: _vm.detailPop,
              callback: _vm.callback,
            },
            model: {
              value: _vm.vValue,
              callback: function ($$v) {
                _vm.vValue = $$v
              },
              expression: "vValue",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }