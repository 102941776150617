var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.isSelected ? "selected-container" : "" }, [
    _c("div", { staticClass: "pin" }),
    _c("div", { staticClass: "pulse" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }