<!--
목적 : 부서 멀티선택 팝업 컴포넌트
-->
<template>
  <div class="fix-height">
    <q-field
      ref="customDeptMulti"
      class="customDeptMulti"
      :dense="dense"
      stack-label
      filled
      color="orange-custom"
      :label="convertLabel"
      :disable="disabled||!editable"
      :rules="!required ? null : [val => !!val || '']"
      v-model="valueText"
      >
      <template v-slot:label v-if="label">
        <div class="row items-center all-pointer-events">
          <b>
            <i v-if="!required" class="pe-7s-note labelfrontIcon searchAreaLabelIcon"></i>
            <font class="formLabelTitle">{{convertLabel}}</font>
            <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
          </b>
        </div>
      </template>
      <template v-slot:control>
        <q-chip
          outline square
          v-for="(item, index) in setValueText()"
          :key="index"
          color="orange-6"
          text-color="white"
          class="q-ma-none customchipdept">
          {{item}}
        </q-chip>
      </template>

      <template v-slot:append>
        <q-icon v-if="!disabled&&editable" name="search" @click="search" class="cursor-pointer"></q-icon>
        <q-icon v-if="!disabled&&editable" name="close" @click="reset" class="cursor-pointer"></q-icon>
      </template>
    </q-field>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import mixinCommon from './js/mixin-common'
export default {
  name: 'c-dept-multi',
  mixins: [mixinCommon],
  props: {
    name: {
      type: String,
      default: 'field',
    },
    // TODO : 부모의 v-model의 값을 받아오는 속성
    value: { // 사용자의 id를 받아 처리
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    // 쓰기 권한 여부
    editable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    // 필수 입력 여부
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: String,
      default: 'single',
    },
    plantCd: {
      type: String,
      default: '',
    },
    parentCheckDepts: {
      type: String,
      default: '',
    },
    stype: {
      type: String,
      default: '20', // 10:상위공정, 20: 단위공정
    }
  },
  data() {
    return {
      vValue: null,
      valueText: '',
      valueTextArray: [],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
  },
  watch: {
    // todo : 부모의 v-model 변경을 감시
    value: {
      handler: function () {
        this.vValue = this.value;
        this.setValueText();
      },
      deep: true,
    },
  },
  /* Vue lifecycle: created, mounted, destroyed, etc */
  beforeMount() {
    this.vValue = this.value;
    this.setValueText();
  },
  mounted() {
    if (!this.disabled) {
      this.$refs['customDeptMulti'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  beforeDestroy() {
    if (!this.disabled) {
      this.$refs['customDeptMulti'].$el.getElementsByClassName('q-field__native')[0].addEventListener('click', this.search);
    }
  },
  /* methods */
  methods: {
    /**
     * v-model로 받은 값을 통해 사용자 정보 조회
     *
     * type에 따라 valueText에 표시되는 값 변경
     */
    setValueText() {
      if (this.vValue) {
        let _list = [];
        this.$_.forEach(this.$store.state.systemInfo.depts, _item => {
          if (this.vValue.indexOf(_item.deptCd) > -1) {
            _list.push(_item.deptName);
          }
        })
        this.valueText = _list.join(',');
        return _list;
      } else {
        this.valueText = '';
        return null;
      }
    },
    search() {
      this.popupOptions.title = 'LBL0000518'; // 부서조회
      this.popupOptions.param = {
        plantCd: this.plantCd,
        parentCheckDepts: this.parentCheckDepts,
      }
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closeDept;
    },
    closeDept(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        var pName = [];
        var pCd = [];
        this.$_.forEach(data, item => {
          pName.push(item.deptName)
          pCd.push(item.deptCd)
        });
        this.valueText = pName.join(',');
        this.$emit('setDeptName', pName.join(','));
        this.$emit('datachange', pCd.join(','));
        this.$emit('input', pCd.join(','));
      }
    },
    reset() {
      this.valueText = '';
      this.$emit('input', null);
      this.$emit('setDeptName', this.valueText);
      this.$emit('datachange', null);
    },
  },
};
</script>
<style lang="sass">
.customDeptMulti
  .q-field__control-container
    padding-bottom: 2px !important
  padding-bottom: 20px !important
  .q-field__native.row
    padding-left: 3px
    font-size: 0.95em
    padding-top: 4px !important
  .q-field__native
    padding-bottom: 0px !important
    cursor: pointer
  .q-field__label
    margin-left: 5px
  .material-icons
    font-size: 0.8rem
  .dateCloseIcon
    font-size: 0.8rem !important
    display: none
.customDeptMulti:hover
  .dateCloseIcon
    display: block
.customDeptMulti.q-field--dense .q-field__control, .customDeptMulti.q-field--dense .q-field__marginal
  height: 33px !important
  min-height: 31px !important
  height: min-content !important
.customDeptMulti.q-field--filled .q-field__control
  border: 1px solid rgba(0,0,0,0.15) !important
  padding: 0px 8px 0px 2px
.customchipdept.q-chip
  margin-top: -3px !important
  margin-right: 3px !important
  .q-chip__content
    font-size: 0.95em !important
</style>