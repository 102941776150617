<template>
  <div class="app-container app-theme-white page-layout">
    <div v-if="modeEnv" class="envTxtPage">{{ modeEnv }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  beforeMount() {
    switch(process.env.NODE_ENV) {
      case 'test':
        this.modeEnv = 'LOCAL개발';
        break;
      case 'development':
        this.modeEnv = 'AWS개발';
        break;
      case 'production':
        this.modeEnv = '';
        break;
      default:
        this.modeEnv = '';
    }
  },
  data() {
    return {
      modeEnv: '',
    };
  },
  methods: {}
};
</script>
<style lang="scss">
.envTxtPage {
  padding: 0 10px;
  position: fixed;
  bottom: 10px;
  right: 0px;
  font-weight: 700;
  font-size: 1.1em;
  color:#fff !important ;
  background: red;
  border-radius: 10px 0px 0px 10px;
  z-index: 999;
}
</style>
