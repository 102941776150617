/* eslint-disable */
import {
  asyncRouterMap
} from '@/router'
import _ from 'lodash'
import {
  getUserMenus,
  getVendorMenus,
} from './login';
import store from '@/store';
import comm from '@/js/common';

function getComponet(subLevel, frontEndUrl) {
  let componet;
  if (!frontEndUrl) {
    // url은 최하단, 즉 화면에 표시할 수 있는 url이 있는 경우에만 존재
    componet = null;
  } else if (subLevel && subLevel.length > 0 && frontEndUrl) {
    // 하위 메뉴가 존재하는데 url이 있을 시 component는 null 처리하여 메뉴 클릭시 혼돈이 없게 처리
    componet = null;
  } else {
    componet = () => import(`@/pages${frontEndUrl}.vue`);
  }
  return componet;
}

function accessRouters(_menus) {
  let accessRouters = [];
  let haveUrlMenus = _.filter(_menus, (item) => {
    return item.url
  })

  if (haveUrlMenus) {
    let subLevel = [];
    _.forEach(haveUrlMenus, menu => {
      subLevel = _.filter(_menus, {
        'menuLvl': String(menu.menuLvl + 1),
        'upMenuId': menu.sysMenuId
      });
      
      let url = menu.url
      let paramUrl = ''
      let params = null;
      let paramIndex = _.indexOf(menu.url, '?')
      if (paramIndex > -1) {
        let paramString = menu.url.substring(paramIndex + 1, menu.url.length);
        params = _.fromPairs(_.map(paramString.split('&'), text => {
          return text.split('=')
        }));
        
        url = url.substring(0, paramIndex)

        for (let key in params) {
          paramUrl += `/${params[key]}`
        }
      }
      accessRouters.push({
        path: `${url}${paramUrl}`,
        name: menu.sysMenuId,
        meta: {
          title: menu.menuNm,
          layout: (menu.sysMenuId === 'MENU000120' && menu.menuViewMethod == 'horizon') ? 'main-horizon' : ((menu.sysMenuId === 'MENU100237' && menu.menuViewMethod == 'default') ? 'main' : menu.menuViewMethod),
          manual: menu.manualFlag === 'Y' ? true : false,
          // 권한 처리 되고 난 후에 해당 로직 처리
          editable: menu.userWriteFlag === 'Y' ? true : false, // menu.writeYn === 'Y' ? true : false,
          params: params,
        },
        component: getComponet(subLevel, url),
      });
    })
  }
  return accessRouters;
}

function createLeftMenu(_Allmenus, _menus, menuLevel, navi) {
  let menuLevel1 = _.filter(_menus, {
    'menuLvl': menuLevel.toString()
  });
  _.forEach(menuLevel1, (menu) => {
    menu.sortOrder = menu.sortOrder ? !isNaN(menu.sortOrder) ? _.parseInt(menu.sortOrder) : 0 : 0
  });
  menuLevel1 = _.orderBy(menuLevel1, ['sortOrder'], ['asc'])

  let returnMenu = [];
  let subLevel = [];
  _.forEach(menuLevel1, (menu) => {
    subLevel = _.filter(_Allmenus, {
      'menuLvl': String(menuLevel + 1),
      'upMenuId': menu.sysMenuId
    });

    let url = menu.url
    let paramUrl = ''
    let params = null;
    let paramIndex = _.indexOf(menu.url, '?')
    if (paramIndex > -1) {
      let paramString = menu.url.substring(paramIndex + 1, menu.url.length);
      params = _.fromPairs(_.map(paramString.split('&'), text => {
        return text.split('=')
      }));
      
      url = url.substring(0, paramIndex)

      for (let key in params) {
        paramUrl += `/${params[key]}`
      }
    }

    let menuInfo = {
      title: menu.menuNm,
      icon: menu.menuIcon,
      name: menu.sysMenuId,
      href: url ? `${url}${paramUrl}` : '',
      header: (menu.url === null || menu.url === '' ? '' : 'generic'),
      manual: menu.manualFlag === 'Y' ? true : false,
      params: params,
    }

    // if (menuLevel === 1) {
    //   menuInfo.meta.color = menu.color;
    //   menuInfo.meta.icon = menu.icon;
    //   menuInfo.path = '/' + frontEndUrlSplit[frontEndUrlSplit.length - 1];
    // } else {
    //   menuInfo.path = frontEndUrlSplit[frontEndUrlSplit.length - 1];
    // }

    if (subLevel && subLevel.length > 0) {
      menuInfo.child = createLeftMenu(_Allmenus, subLevel, menuLevel + 1, navi ? navi + ' > ' + menu.menuNm : menu.menuNm)
    }
    returnMenu.push(menuInfo);
  });
  return returnMenu;
}

const permission = {
  state: {
    routers: [],
    addRouters: [],
  },
  mutations: {
    SET_VIEW_MENU: (state, viewRouters) => {
      /**
       * LEFT 메뉴를 저장
       */
      /**
       * 후에 소스에서 메뉴를 관리하며 해당 메뉴를 left에 표시할 경우 사용
       */
      _.forEach(asyncRouterMap, item => {
        if (_.findIndex(viewRouters, { name: item.name }) === -1 && !item.hidden) {
          viewRouters.splice(0, 0, {
            href: item.path,
            icon: item.icon,
            name: item.name,
            title: item.title,
            manual: item.manualFlag === 'Y' ? true : false,
            component: item.component,
            meta: {
              editable: true,
            },
          })
        }
      })
      state.routers = viewRouters
    },
    SET_ROUTERS: (state, accessedRouters) => {
      state.addRouters = asyncRouterMap.concat(accessedRouters)
    },
  },
  actions: {
    GenerateRoutes({
      commit
    }, data) {
      // const userId = this.state.user.user.userId.trim();
      return new Promise((resolve, reject) => {
        getUserMenus(data.empNo)
          .then(response => {
            let viewRouters = createLeftMenu(response.data, response.data, 0, '')
            let accessedRouters = accessRouters(response.data)

            commit('SET_VIEW_MENU', viewRouters);
            commit('SET_ROUTERS', accessedRouters);

            resolve()
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    GenerateVendorRoutes({
      commit
    }, data) {
      // const userId = this.state.user.user.userId.trim();
      return new Promise((resolve, reject) => {
        getVendorMenus()
          .then(response => {
            let viewRouters = createLeftMenu(response.data, response.data, 0, '')
            let accessedRouters = accessRouters(response.data)

            commit('SET_VIEW_MENU', viewRouters);
            commit('SET_ROUTERS', accessedRouters);

            resolve()
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
}

export default permission
