/* eslint-disable */
// 로그인시 처리되는 모듈
import axios from 'axios';
import store from '@/store';
import {
  getToken
} from '@/utils/auth';
import {
  getAccessToken,
  getNewToken,
  getAccessExpiredCode,
  getRefreshExpiredCode,
  getWrongPasswordCode,
  getConcurrentExpiredCode,
} from './auth';
import common from '../js/common';

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_API_URL, // api base_url
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000, // request timeout
  withCredentials: true, // 쿠키전달용
  // responseEncoding: 'utf-8',
  // crossDomain: true,
});

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    if (store.getters.token) {
      // X-Token으로 사용자 정의 요청 수행
      config.headers['x-token'] = getToken();
      config.headers['X-Authorization'] = `${getAccessToken()}`;
    }
    return config;
  },
  error => {
    // Do something with request error
    window.getApp.$emit('APP_REQUEST_ERROR', error);
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error.response ? error.response.status : null;
    const returnCode = error.response ? error.response.data.returnCode : null;
    if (status === 401) {
      if (returnCode === getAccessExpiredCode()) {
        // Access Token 만료 : Refresh Token으로 재인증 요청
        // console.log('-------------- 2-1. Access Token 만료')
        // 1. Refresh Token 확인 요청
        return getNewToken().then(_res => {
          // 2. 새로 발급된 Access Token 으로 재요청
          // console.log('-------------- 2-3. 새로 발급된 Access Token 으로 재요청')
          let token = getAccessToken();
          service.defaults.headers.common['X-Authorization'] = token;
          error.config.headers['X-Authorization'] = token;
          // 이전요청을 다시 수행하도록 url및 parameter등을 다시 호출.
          return service.request(error.config);
          // if (typeof _callbackSuccess === 'function') _callbackSuccess({});
          // if (http.isLoading) window.getApp.$emit('LOADING_HIDE');
          // http.isLoading = true;
        });
      } else if (returnCode === getRefreshExpiredCode()) {
        // Refresh Token 만료
        // 1. 비밀번호 확인 팝업 띄우기
        common.openCheckPwdPopup();
      } else if (returnCode === getWrongPasswordCode()) {
        // 비밀번호가 맞지 않을 때만 진입
        return Promise.reject(error);
      } else if (returnCode === getConcurrentExpiredCode()) {
        // 새로운 IP로 로그인 했을 때만 진입
        common.openCheckLoginPopup();
        return;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
