var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header-dots" }, [
    _c(
      "span",
      {
        staticStyle: { display: "inline" },
        attrs: { slot: "button-content zoomcontent" },
        slot: "button-content zoomcontent",
      },
      [
        _c(
          "q-btn-group",
          { staticClass: "zoomgroup", attrs: { outline: "" } },
          [
            _c(
              "q-btn",
              {
                staticClass: "zoombtn zoomicon",
                attrs: {
                  flat: "",
                  size: "xs",
                  dense: "",
                  color: "white",
                  icon: "remove",
                },
                on: { click: _vm.zoomMinus },
              },
              [
                _c("q-tooltip", [
                  _vm._v(" " + _vm._s(_vm.setLabel("LBL0000855")) + " "),
                ]),
              ],
              1
            ),
            _c(
              "q-btn",
              {
                staticClass: "zoombtn textzoom",
                attrs: { flat: "", dense: "", size: "xs", color: "white" },
                on: { click: _vm.zoomInit },
              },
              [
                _vm._v(" " + _vm._s(_vm.zoomText) + " "),
                _vm.zoomText !== "100%"
                  ? _c("q-tooltip", [
                      _vm._v(" " + _vm._s(_vm.setLabel("LBL0000856")) + " "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "q-btn",
              {
                staticClass: "zoombtn zoomicon",
                attrs: {
                  flat: "",
                  dense: "",
                  size: "xs",
                  color: "white",
                  icon: "add",
                },
                on: { click: _vm.zoomPlus },
              },
              [
                _c("q-tooltip", [
                  _vm._v(" " + _vm._s(_vm.setLabel("LBL0000857")) + " "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }